import { Injectable } from '@angular/core';
import { ApiGatewayService } from '@wam/authentication';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AnalyticsFacets, Organization } from '../analytics.model';
import { AnalyticsQuery } from '../analytics-query.model';
import {
  AnalyticsSchool,
  ClassroomAnalytics,
  Facets,
  StudentAnalytics,
  ParentHomeDocument,
} from '@wam/shared';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private static rolesDisplayValues = {
    teacher: 'Teacher',
    school_admin: 'School Admin',
    district_admin: 'District Admin',
  };

  constructor(private apiGatewayService: ApiGatewayService) {}

  static transformFacets(analyticsFacets: AnalyticsFacets[]): Facets {
    const facets: Facets = {};
    if (analyticsFacets) {
      analyticsFacets.forEach((userFacet) => {
        Object.keys(userFacet).forEach((key) => {
          facets[key] = userFacet[key].map((item) => {
            switch (key) {
              case 'schools':
              case 'classes':
              case 'tags':
                return { value: item.uuid, display: item.name };
              case 'unassignedStudents':
                return {
                  value: item,
                  display: item === 'true' ? 'Show Unassigned' : 'Show Assigned',
                };
              case 'roles':
                return {
                  value: item,
                  display: this.rolesDisplayValues[item] ?? item,
                };
              case 'courseAssignments':
                return { value: item.key, display: item.name };
              default:
                return { value: item, display: item };
            }
          });
        });
      });
    }
    return facets;
  }

  getClasses(page: number): Observable<ClassroomAnalytics[]> {
    return this.apiGatewayService
      .get(`analytics/v3/indexes/esd_wra_edu/views/classes/query?page=${page}&limit=100`)
      .pipe(map((response: { hits: ClassroomAnalytics[] }) => response.hits));
  }

  getClassesWithFacets(
    query: AnalyticsQuery,
    page: number,
  ): Observable<[ClassroomAnalytics[], number, Facets]> {
    return this.apiGatewayService
      .get(
        `analytics/v3/indexes/esd_wra_edu/views/classes/query?page=${page}&limit=100${this.queryString(
          query,
        )}`,
      )
      .pipe(
        take(1),
        map(
          (response: {
            hits: ClassroomAnalytics[];
            totalHits: number;
            facets: AnalyticsFacets[];
          }) => [
            response.hits,
            response.totalHits,
            AnalyticsService.transformFacets(response.facets),
          ],
        ),
      );
  }

  getStudentsInClass(classId: string): Observable<StudentAnalytics[]> {
    return this.apiGatewayService
      .get(
        `analytics/v3/indexes/esd_wra_users/views/users_students/query?classes=${classId}&limit=1000`,
      )
      .pipe(map((response: { hits: StudentAnalytics[] }) => response.hits));
  }

  getUsers(
    query: AnalyticsQuery,
    page: number,
    view = 'users_students',
  ): Observable<[StudentAnalytics[], number, Facets]> {
    return this.apiGatewayService
      .get(
        `analytics/v3/indexes/esd_wra_users/views/${view}/query?page=${page}&limit=100${this.queryString(
          query,
        )}`,
      )
      .pipe(
        take(1),
        map(
          (response: {
            hits: StudentAnalytics[];
            facets: AnalyticsFacets[];
            totalHits: number;
          }) => [
            response.hits,
            response.totalHits,
            AnalyticsService.transformFacets(response.facets),
          ],
        ),
      );
  }

  getUserCounts(
    role: string,
    organization: string,
    page = 1,
    school?: string,
  ): Observable<{ uuid: string; name: string; count: number }[]> {
    const orgCounts = (facets) => {
      if (!school)
        return [
          {
            uuid: facets[1].organization[0]?.key ?? organization,
            name: facets[1].organization[0]?.value ?? '',
            count: facets[1].organization[0]?.count ?? 0,
          },
        ];
      return [];
    };
    const schoolParam = school ? `&school=${school}` : '';
    return this.apiGatewayService
      .get(
        `analytics/v3/indexes/esd_wra_users/views/schools_users_count/query?roles=${role}&organization=${organization}${schoolParam}&limit=100&page=${page}`,
      )
      .pipe(
        map(({ facets, hits }) => [
          ...orgCounts(facets),
          ...hits.map(({ uuid, name, count }) => ({ uuid, name, count })),
        ]),
      );
  }

  getSchools(
    query: AnalyticsQuery,
    page: number,
    view = 'schools',
  ): Observable<[AnalyticsSchool[], number, Facets]> {
    return this.apiGatewayService
      .get(
        `analytics/v3/indexes/esd_wra_edu/views/${view}/query?page=${page}&limit=100${this.queryString(
          query,
        )}`,
      )
      .pipe(
        take(1),
        map(
          (response: { hits: AnalyticsSchool[]; totalHits: number; facets: AnalyticsFacets[] }) => [
            response.hits,
            response.totalHits,
            AnalyticsService.transformFacets(response.facets),
          ],
        ),
      );
  }

  private queryString(query: AnalyticsQuery): string {
    return query.toString() ? `&${query}` : '';
  }

  searchOrganization(keyword: string): Observable<Organization[]> {
    return this.apiGatewayService
      .get(
        `analytics/v3/indexes/esd_wra_edu/views/org_codes/query?search=${encodeURIComponent(keyword)}`,
      )
      .pipe(map((response: { hits: Organization[] }) => response.hits));
  }

  getOrganizations(orgs: string[]): Observable<Organization[]> {
    return this.apiGatewayService
      .get(`analytics/v3/indexes/esd_wra_edu/views/org_codes/query?organization=${orgs.toString()}`)
      .pipe(map((response: { hits: Organization[] }) => response.hits));
  }

  getStudentPlaytime(orgId: string, studentId: string): Observable<ParentHomeDocument> {
    return this.apiGatewayService.get(
      `analytics/v3/indexes/esd_wra_users/documents/${orgId}_${studentId}`,
    );
  }
}
